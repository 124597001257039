import * as React from "react";
import { ButtonType } from "../enums/Button";

interface props {
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    type?: ButtonType,
    disabled?: boolean
    className?: string,
    text: string,

}

const Button: React.FC<props> = ({ onClick, type = ButtonType.BUTTON, disabled = false, className, text }) => {
    return (
        <>
            <button className={className + " text-base leading-tight px-4 py-1 disabled:opacity-25 rounded bg-[#181b1e] hover:bg-[#212529] border-gray-800 hover:border-gray-900 text-white"}
                onClick={onClick}
                type={type}
                disabled={disabled}
            >
                {text}
            </button>
        </>
    );
};
export default Button;