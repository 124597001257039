import React from "react";
import { AuthProvider } from "react-auth-kit";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";



ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
	<React.StrictMode>
		<AuthProvider
			authType={"localstorage"}
			authName={"_auth"}
		// cookieDomain={window.location.hostname}
		// cookieSecure={window.location.protocol === "https:"}
		>
			<BrowserRouter>

				{/* <App configPromise={configPromise}/> */}

					<App />

			</BrowserRouter>
		</AuthProvider>
	</React.StrictMode>
);
