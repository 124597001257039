import * as React from "react";

interface props {
    id: string,
    onBlurHandler?: (value: string | undefined) => void
    hasError?: boolean,
    disabled?: boolean
    placeholder?: string,
    extraClasses?: string
}

const TextInput: React.FC<props> = ({
    id,
    onBlurHandler,
    hasError = false,
    disabled = false,
    placeholder = " ",
    extraClasses = "",
}) => {

    const [value, setValue] = React.useState('');

    const onBlur = () => {
        if (onBlurHandler !== undefined) {
            onBlurHandler(value);
        }
        return;
    }

    return (
        <>
            <input
                id={id}
                type="text"
                className={"peer border p-3 pb-1 pt-4 " + (hasError ? "border-rose-600 " : "border-black ") + (disabled ? " bg-slate-100 " : " bg-white ") + (extraClasses)}
                value={value}
                onChange={e => setValue(e.target.value)}
                onBlur={onBlur}
                disabled={disabled}
                placeholder={placeholder}
            />
        </>
    );
};
export default TextInput;