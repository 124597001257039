/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Soya.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import isomorphicFetch from "isomorphic-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "/".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration?: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name = "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface BatchUpdateResult
 */
export interface BatchUpdateResult {
    /**
     * 
     * @type {string}
     * @memberof BatchUpdateResult
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    lastname?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    customerId?: string;
    /**
     * 
     * @type {Array<EmailAddress>}
     * @memberof Contact
     */
    email?: Array<EmailAddress>;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    passwordSalt?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    passwordHashType?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    id?: string;
}
/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface EmailAddress
 */
export interface EmailAddress {
    /**
     * 
     * @type {boolean}
     * @memberof EmailAddress
     */
    validated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmailAddress
     */
    validationToken?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailAddress
     */
    address?: string;
}
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    access_token?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    token_type?: string;
    /**
     * 
     * @type {number}
     * @memberof LoginResponse
     */
    expires_in?: number;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    refresh_token?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    scope?: string;
}
/**
 * 
 * @export
 * @interface PasswordLogin
 */
export interface PasswordLogin {
    /**
     * 
     * @type {string}
     * @memberof PasswordLogin
     */
    vendorNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordLogin
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordLogin
     */
    beneficiary?: string;
}
/**
 * 
 * @export
 * @interface PurchaseOrder
 */
export interface PurchaseOrder {
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrder
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrder
     */
    supplierId?: string;
    /**
     * 
     * @type {Array<PurchaseOrderLine>}
     * @memberof PurchaseOrder
     */
    lines?: Array<PurchaseOrderLine>;
}
/**
 * 
 * @export
 * @interface PurchaseOrderLine
 */
export interface PurchaseOrderLine {
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderLine
     */
    purchaseOrderId?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderLine
     */
    itemNo?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderLine
     */
    variant?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderLine
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderLine
     */
    lineNo?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PurchaseOrderLine
     */
    prepack?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PurchaseOrderLine
     */
    specialVariant?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderLine
     */
    quantityItem?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderLine
     */
    quantityBox?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderLine
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderLine
     */
    size?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderLine
     */
    skuDetails?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderLine
     */
    locationCode?: string;
}
/**
 * 
 * @export
 * @interface PurchaseOrderLineResponse
 */
export interface PurchaseOrderLineResponse {
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderLineResponse
     */
    purchaseOrderId?: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderLineResponse
     */
    lineNo?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderLineResponse
     */
    bookingNo?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderLineResponse
     */
    packageNo?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderLineResponse
     */
    itemNo?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderLineResponse
     */
    variant?: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderLineResponse
     */
    quantityItem?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderLineResponse
     */
    quantityBox?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PurchaseOrderLineResponse
     */
    backOrder?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderLineResponse
     */
    weightNet?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderLineResponse
     */
    weightGross?: number;
}
/**
 * AuthApi - fetch parameter creator
 * @export
 */
export const AuthApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PasswordLogin} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthLoginPost(body?: PasswordLogin, options: any = {}): FetchArgs {
            const localVarPath = `/api/Auth/Login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PasswordLogin" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PasswordLogin} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthLoginPost(body?: PasswordLogin, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LoginResponse> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).apiAuthLoginPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {PasswordLogin} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthLoginPost(body?: PasswordLogin, options?: any) {
            return AuthApiFp(configuration).apiAuthLoginPost(body, options)(fetch, basePath);
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {PasswordLogin} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthLoginPost(body?: PasswordLogin, options?: any) {
        return AuthApiFp(this.configuration).apiAuthLoginPost(body, options)(this.fetch, this.basePath);
    }

}
/**
 * ContactApi - fetch parameter creator
 * @export
 */
export const ContactApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCustomerIdContactIdGet(id: string, customerId: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiCustomerCustomerIdContactIdGet.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling apiCustomerCustomerIdContactIdGet.');
            }
            const localVarPath = `/api/Customer/{customerId}/Contact/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactApi - functional programming interface
 * @export
 */
export const ContactApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCustomerIdContactIdGet(id: string, customerId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Contact> {
            const localVarFetchArgs = ContactApiFetchParamCreator(configuration).apiCustomerCustomerIdContactIdGet(id, customerId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ContactApi - factory interface
 * @export
 */
export const ContactApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCustomerIdContactIdGet(id: string, customerId: string, options?: any) {
            return ContactApiFp(configuration).apiCustomerCustomerIdContactIdGet(id, customerId, options)(fetch, basePath);
        },
    };
};

/**
 * ContactApi - object-oriented interface
 * @export
 * @class ContactApi
 * @extends {BaseAPI}
 */
export class ContactApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public apiCustomerCustomerIdContactIdGet(id: string, customerId: string, options?: any) {
        return ContactApiFp(this.configuration).apiCustomerCustomerIdContactIdGet(id, customerId, options)(this.fetch, this.basePath);
    }

}
/**
 * CustomerApi - fetch parameter creator
 * @export
 */
export const CustomerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCustomerIdGet(customerId: string, options: any = {}): FetchArgs {
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling apiCustomerCustomerIdGet.');
            }
            const localVarPath = `/api/Customer/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCustomerIdGet(customerId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Customer> {
            const localVarFetchArgs = CustomerApiFetchParamCreator(configuration).apiCustomerCustomerIdGet(customerId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCustomerIdGet(customerId: string, options?: any) {
            return CustomerApiFp(configuration).apiCustomerCustomerIdGet(customerId, options)(fetch, basePath);
        },
    };
};

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * 
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiCustomerCustomerIdGet(customerId: string, options?: any) {
        return CustomerApiFp(this.configuration).apiCustomerCustomerIdGet(customerId, options)(this.fetch, this.basePath);
    }

}
/**
 * PurchaseOrderApi - fetch parameter creator
 * @export
 */
export const PurchaseOrderApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<PurchaseOrderLineResponse>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPurchaseOrderBatchUpdatePost(body?: Array<PurchaseOrderLineResponse>, options: any = {}): FetchArgs {
            const localVarPath = `/api/PurchaseOrder/BatchUpdate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;PurchaseOrderLineResponse&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPurchaseOrderListGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/PurchaseOrder/List`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PurchaseOrderApi - functional programming interface
 * @export
 */
export const PurchaseOrderApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<PurchaseOrderLineResponse>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPurchaseOrderBatchUpdatePost(body?: Array<PurchaseOrderLineResponse>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BatchUpdateResult> {
            const localVarFetchArgs = PurchaseOrderApiFetchParamCreator(configuration).apiPurchaseOrderBatchUpdatePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPurchaseOrderListGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<PurchaseOrder>> {
            const localVarFetchArgs = PurchaseOrderApiFetchParamCreator(configuration).apiPurchaseOrderListGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PurchaseOrderApi - factory interface
 * @export
 */
export const PurchaseOrderApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {Array<PurchaseOrderLineResponse>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPurchaseOrderBatchUpdatePost(body?: Array<PurchaseOrderLineResponse>, options?: any) {
            return PurchaseOrderApiFp(configuration).apiPurchaseOrderBatchUpdatePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPurchaseOrderListGet(options?: any) {
            return PurchaseOrderApiFp(configuration).apiPurchaseOrderListGet(options)(fetch, basePath);
        },
    };
};

/**
 * PurchaseOrderApi - object-oriented interface
 * @export
 * @class PurchaseOrderApi
 * @extends {BaseAPI}
 */
export class PurchaseOrderApi extends BaseAPI {
    /**
     * 
     * @param {Array<PurchaseOrderLineResponse>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrderApi
     */
    public apiPurchaseOrderBatchUpdatePost(body?: Array<PurchaseOrderLineResponse>, options?: any) {
        return PurchaseOrderApiFp(this.configuration).apiPurchaseOrderBatchUpdatePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseOrderApi
     */
    public apiPurchaseOrderListGet(options?: any) {
        return PurchaseOrderApiFp(this.configuration).apiPurchaseOrderListGet(options)(this.fetch, this.basePath);
    }

}
/**
 * VersionApi - fetch parameter creator
 * @export
 */
export const VersionApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVersionGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/Version`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VersionApi - functional programming interface
 * @export
 */
export const VersionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVersionGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = VersionApiFetchParamCreator(configuration).apiVersionGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * VersionApi - factory interface
 * @export
 */
export const VersionApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVersionGet(options?: any) {
            return VersionApiFp(configuration).apiVersionGet(options)(fetch, basePath);
        },
    };
};

/**
 * VersionApi - object-oriented interface
 * @export
 * @class VersionApi
 * @extends {BaseAPI}
 */
export class VersionApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionApi
     */
    public apiVersionGet(options?: any) {
        return VersionApiFp(this.configuration).apiVersionGet(options)(this.fetch, this.basePath);
    }

}
