import { Cell, Row, RowData, Table } from "@tanstack/react-table";
import * as React from "react";
import { InputTypes, InputWidth } from "../../enums/Input";
import { TableData } from "../../interfaces/TableData";

interface props {
	cell: Cell<TableData, any>;
	row: any;
	column: any;
	tableFunction: Table<any>;
	width?: InputWidth;
	inputType?: InputTypes;
	step?: number;
	min?: number;
	max?: number;
	disabled?: boolean;
	hasError?: (row: Row<TableData>) => boolean;
	processValue?: (value: string | number) => string | number;
	additionalOnBlurAction?: (value: string | number) => void;
}

export const EditableInputCell: React.FC<props> = ({
	cell,
	row,
	column,
	tableFunction,
	width = InputWidth.DEFAULT,
	inputType = InputTypes.TEXT,
	step,
	min,
	max,
	disabled = false,
	hasError,
	processValue = (value) => {
		return value;
	},
	additionalOnBlurAction
}) => {
	const initialValue = cell.getValue();
	// We need to keep and update the state of the cell normally
	const [value, setValue] = React.useState(
		inputType === InputTypes.TEXT ? "" : 0
	);

	// When the input is blurred, we'll call our table meta's updateData function
	const onBlur = () => {
		if (value === initialValue) {
			return;
		}

		tableFunction.options.meta?.updateData(
			row.index,
			column.id,
			processValue(value)
		);
		setValue(processValue(value)); // Force update the field visually.
		if (additionalOnBlurAction === undefined) { return; }
		additionalOnBlurAction(value);
	};

	// If the initialValue is changed external, sync it up with our state
	React.useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	return (
		<input
			className={
				width +
				" border-2 " +
				(hasError?.(row) ? " border-rose-600" : "border-transparent")
			}
			value={value}
			onChange={(e) => setValue(e.target.value)}
			onBlur={onBlur}
			type={inputType}
			step={step}
			min={min}
			max={max}
			disabled={disabled}
		/>
	);
};
