enum RoutePaths {
    Home = '/',
    Edit = '/edit',
    Login = '/login',
    
    Nonexistent = '*',
    Unauthorized = '/login', 
}

export function getRouteWithId(routePath: RoutePaths, id: string) {
    return routePath.replace(':id', id);
}

export default RoutePaths;
