import { Cell, Row, RowData, Table } from "@tanstack/react-table";
import * as React from "react";
import { InputTypes, InputWidth } from "../../enums/Input";
import { TableData } from "../../interfaces/TableData";

interface props {
    cell: Cell<TableData, any>;
    row: any;
    column: any;
    tableFunction: Table<any>;
    width?: InputWidth;
    step?: number;
    min?: number;
    max?: number;
    disabled?: boolean;
    hasError?: (row: Row<TableData>) => boolean;
    processValue?: (value: number | undefined) => number;
    additionalOnBlurAction?: (value: number | undefined) => void;
}

export const EditableNumberCell: React.FC<props> = ({
    cell,
    row,
    column,
    tableFunction,
    width = InputWidth.DEFAULT,
    step,
    min,
    max,
    disabled = false,
    hasError,
    processValue = (value) => {
        return value;
    },
    additionalOnBlurAction
}) => {
    const initialValue = cell.getValue();
    const [value, setValue] = React.useState<number | undefined>(0);

    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = () => {
        if (value === initialValue) { return; }
        tableFunction.options.meta?.updateData(row.index, column.id, value);
        if (additionalOnBlurAction === undefined) { return; }
        additionalOnBlurAction(value);
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return (
        <input
            className={width + " border-2 " + (hasError?.(row) ? " border-rose-600" : "border-transparent")}
            value={value}
            onChange={(e) => isNaN(e.target.valueAsNumber) ? setValue(undefined) : setValue(processValue(e.target.valueAsNumber))}
            onBlur={onBlur}
            type="number"
            step={step}
            min={min}
            max={max}
            disabled={disabled}
        />
    );
};
