export enum InputTypes{
    TEXT = "text",
    NUMBER = "number"
}

export enum InputWidth{
    NARROW = "w-16",
    DEFAULT = "w-20",
    WIDE = "w-24",
    ULTRAWIDE = "w-28"
}