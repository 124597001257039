import * as React from "react";

interface props { }

const Loading: React.FC<props> = ({ }) => {
    return (
        <div className="loading w-full h-full">
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};
export default Loading;