import * as React from "react";

interface props {
    optionValues: string[]
    labelName: string
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void; 
    value: string; 
}

const Select: React.FC<props> = ({ optionValues, labelName, onChange , value}) => {
    return (
        <>
            <label className="font-bold" htmlFor={labelName}>{labelName}</label>
            <select className="border-solid border block w-full rounded-sm px-2 py-1 mb-3" value={value} title={labelName} name={labelName} onChange={onChange}>
                {
                    optionValues.map((value, index) => (
                        <option key={index} value={value}>{value}</option>
                    ))
                }
            </select >
        </>
    );
};
export default Select;