import * as React from "react";
import {useIsAuthenticated} from 'react-auth-kit';
import { Navigate, Outlet } from "react-router-dom";
import RoutePaths from "../enums/RoutePaths";

export interface props {}

const AuthenticatedWrapper: React.FC<props> = ({}) => {
    const isAuthenticated = useIsAuthenticated()
    return  isAuthenticated() ? <Outlet/> : <Navigate to={RoutePaths.Unauthorized} />
  
};
export default AuthenticatedWrapper;