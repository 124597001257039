import * as React from "react";
import {
    flexRender
} from '@tanstack/react-table';
import { Row, Table } from '@tanstack/table-core';
import { useVirtual } from "react-virtual";
import { TableData } from "../../interfaces/TableData";
import Loading from "../Loading";

interface props {
    table: Table<any>
    headerCount: number
    loading?: boolean
    customHeight?: string
}

const TableComponent: React.FC<props> = ({ table, headerCount, loading = false, customHeight }) => {

    const tableContainerRef = React.useRef<HTMLDivElement>(null)

    const { rows } = table.getRowModel()

    const rowVirtualizer = useVirtual({
        parentRef: tableContainerRef,
        size: rows.length,
        overscan: 10,

    })
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer
    const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0
    const paddingBottom =
        virtualRows.length > 0
            ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
            : 0


    return (
        <>
            {loading ?
                <Loading />
                :
                <div className="overflow-y-auto mb-3 whitespace-no-wrap relative">
                    <div ref={tableContainerRef} className={"overflow-auto " + (customHeight === undefined ? " h-[calc(100vh-70px)]" : customHeight)}>
                        <table className="w-full table-fixed">
                            <thead className="table-head bg-[#212529] text-white sticky top-0">
                                {table.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map(header => (
                                            <th className="text-left py-2 px-4" style={{ width: header.getSize() }} key={header.id}>
                                                {header.isPlaceholder
                                                    ? null
                                                    : flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {paddingTop > 0 && (
                                    <tr>
                                        <td colSpan={headerCount} style={{ height: `${paddingTop}px` }} />
                                    </tr>
                                )}
                                {virtualRows.map(virtualRow => {
                                    const row: Row<TableData> = rows[virtualRow.index]
                                    return (
                                        <tr className={(virtualRow.index % 2 ? 'ListItemOdd' : 'ListItemEven')} onClick={() => row.toggleSelected(!row.getIsSelected())} key={row.id}>
                                            {row.getVisibleCells().map(cell => {
                                                return (
                                                    <td className="text-left py-2 px-4" key={cell.id}>
                                                        {flexRender(
                                                            cell.column.columnDef.cell,
                                                            cell.getContext()
                                                        )}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                                {paddingBottom > 0 && (
                                    <tr>
                                        <td colSpan={headerCount} style={{ height: `${paddingBottom}px` }} />
                                    </tr>
                                )}
                            </tbody>
                            <tfoot className="table-foot bg-[#212529] text-white">
                                {table.getFooterGroups().map(footerGroup => (
                                    <tr key={footerGroup.id}>
                                        {footerGroup.headers.map(header => (
                                            <th className=" text-left py-2 px-4" key={header.id}>
                                                {header.isPlaceholder
                                                    ? null
                                                    : flexRender(
                                                        header.column.columnDef.footer,
                                                        header.getContext()
                                                    )}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </tfoot>
                        </table>
                    </div>
                </div>
            }
        </>
    );
};

export default TableComponent;