import * as React from "react";
import { useIsAuthenticated, useSignIn } from 'react-auth-kit'
import { Navigate, useNavigate } from 'react-router-dom';
import { AuthApi, Configuration, LoginResponse } from "../api";
import Select from "../common/components/Dropdown";
import RoutePaths from "../common/enums/RoutePaths";


export interface props {
    baseUrl: string;
}

export const LoginPage: React.FC<props> = ({ baseUrl }) => {
    const isAuthenticated = useIsAuthenticated()

    const options = ["EU", "NO", "UK"];
    const [vendor, setVendor] = React.useState<string>("");
    const [password, setpassword] = React.useState<string>("");
    const [beneficiary, setBeneficiary] = React.useState<string>(options[0] ?? '');
    const [error, setError] = React.useState<string>('');
    const signIn = useSignIn();
    const navigate = useNavigate();

    const configuration = new Configuration({ basePath: baseUrl });
    const authService = new AuthApi(configuration);

    const handleSubmit = async (event: React.SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            const response = await authService.apiAuthLoginPost({ password: password, vendorNumber: vendor, beneficiary: beneficiary });
            if (await handleResponse(response)) {
                return;
            }
        } catch (err: any) {
            console.error(err.status);
            switch (err.status) {
                case 401:
                    setError('Vendor number or password is wrong');
                    break;
                case err.status >= 500:
                    setError('Internal error, please contact an administrator');
                default:
                    setError('Something went wrong, please contact an administrator');
                    break;
            }
            return;
        }
    }

    const handleResponse = async (response: LoginResponse) => {
        console.log(response.expires_in);
        const authResponse = response.access_token as string;
        let expiresInMinutes = 60; 
        if(response.expires_in !== undefined){
            expiresInMinutes = response.expires_in / 60;
        }
        
        if (signIn({ token: authResponse, expiresIn: expiresInMinutes, authState: { "type": vendor + '_' + beneficiary }, tokenType: 'Bearer', })) {
            navigate(RoutePaths.Home);
            return true;
        }
        return false;
    };

    return (
        <>
            {!isAuthenticated() ? 
            < div className="grid h-screen w-screen place-items-center " >

                <div className="h-auto  max-w-[80%] md:max-w-md lg:max-w-lg py-8 px-5 border-solid border rounded-md">
                    <div className="logo-container mb-5">
                        <img src="/tre_logoer.png" alt="logo" />
                        {/* <p className="text-center">"subtitle.."</p> */}
                    </div>
                    <div className={(error !== "" ? "visible" : "invisible") + " h-10"}>
                        <p className="text-center bg-red-300/25 my-3 p-2">{error}</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <label className="font-bold" htmlFor="vendor">Vendor No.</label>
                        <input className="border-solid border block w-full rounded-sm px-2 py-1 mb-3" type="vendor" name="vendor" id="vendor" placeholder="vendor" value={vendor} onChange={e => setVendor(e.target.value)} />
                        <label className="font-bold" htmlFor="email">Password</label>
                        <input className="border-solid border block w-full rounded-sm px-2 py-1 mb-4" type="password" name="password" id="password" placeholder="Password" value={password} onChange={e => setpassword(e.target.value)} />
                        <Select optionValues={options} labelName={"Beneficiary"} value={beneficiary} onChange={e => setBeneficiary(e.target.value)} />
                        <button type="submit" className="bg-slate-300 hover:bg-slate-400 block w-full rounded-sm p-2" >login</button>
                    </form>

                </div>
            </div >
            : <Navigate to={RoutePaths.Home} /> }
        </>
    );
};
export default LoginPage;