import * as React from "react";

interface props {
    text?: string
    animatedValue: number | string | undefined
    classes?: string;
}

const AnimatedPTag: React.FC<props> = ({ text, animatedValue, classes }) => {

    const [value, setValue] = React.useState<number | string | undefined>("");
    const [key, setKey] = React.useState<number>(0);

    if (value !== animatedValue) {
        setValue(animatedValue);
        setKey(Math.random);
    }

        return (

            <div className={classes + " p-2 rounded flex flex-nowrap bg-slate-300 text-black" }>{text}
                <p key={key} className="flex flex-nowrap ml-[5px] fade">{value}</p>
            </div>

        );
};
export default AnimatedPTag;