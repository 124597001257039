import * as React from "react";
import TextInput from "./TextInput";

interface props {
    onBlurHandler?: (value: string | undefined) => void
    hasError?: boolean
    id: string,
    label: string
    disabled?: boolean
    errorMessage?: string;
}

const FocusableTextInput: React.FC<props> = ({
    onBlurHandler,
    hasError = false,
    id,
    label,
    disabled = false,
    errorMessage = "No Error"
}) => {

    return (

        <div className="relative">
            <TextInput
                id={id}
                extraClasses="placeholder-transparent "
                onBlurHandler={onBlurHandler}
                hasError={hasError}
                disabled={disabled}
                placeholder={label}
            />
            <label htmlFor={id} className="absolute left-3 top-0.5 text-xs text-gray-600 cursor-text peer-placeholder-shown:text-base peer-placeholder-shown:top-2 peer-focus:top-0.5 peer-focus:text-xs transition-all duration-200">
                {label}
            </label>
            <p className={(hasError ? 'visible' : "invisible") + " text-rose-600 block"}>{errorMessage}</p>
        </div>

    );
};
export default FocusableTextInput;