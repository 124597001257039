import * as React from "react";
import {
	useAuthHeader,
	useAuthUser,
	useIsAuthenticated,
	useSignOut,
} from "react-auth-kit";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { Configuration, PurchaseOrderApi, RequiredError } from "../../../api";
import Edit from "../../../pages/Edit";
import Home from "../../../pages/Home";
import { useStorageState } from "../../customHooks/useStorageState";
import RoutePaths from "../../enums/RoutePaths";
import {
	getListOfUniqueObjectsByKey,
	populateUndefinedvalues,
	updateStoredDataWithFetchedData,
} from "../../helperfunctions";
import { TableData } from "../../interfaces/TableData";
import Loading from "../Loading";
import { get, set } from "idb-keyval";

interface props {
	baseURL: string;
	setRerenderKey: React.Dispatch<React.SetStateAction<number>>;
}

const MainLayout: React.FC<props> = ({ baseURL, setRerenderKey }) => {
	const signOut = useSignOut();
	const authHeader = useAuthHeader();
	const auth = useAuthUser();
	const [loading, setLoading] = React.useState<boolean>(true);
	const [error, setError] = React.useState<string>("");
	const [storedData, setStoredData] = React.useState<TableData[]>([]);
	const [fetchedFormattedData, setFetchedFormattedData] = React.useState<
		TableData[] | undefined
	>(undefined);

	//TODO: Move configuration to usercontext.
	const configuration = new Configuration({
		basePath: baseURL,
		apiKey: authHeader(),
	});
	const purchaseOrderService = new PurchaseOrderApi(configuration);


	const getStoredTableValues = async (): Promise<TableData[] | undefined> => {
		const data = await get(auth()?.type + "_tableData");
		if (typeof data === "undefined") {
			return [];
		}
		return data;
	};
	const handleSetTableValues = async (data: TableData[]) => {
		try {
			await set(auth()?.type + "_tableData", data);
			setStoredData(data);
		} catch (e) {
			setError("A problem occured while storing data");
		}
	};

	const [selectedOrders, setSelectedOrders] = useStorageState<string[]>(
		window.localStorage,
		auth()?.type + "_selectedOrders",
		[]
	);
	React.useEffect(() => {
		if (typeof fetchedFormattedData === "undefined") return;
		setFetchedFormattedData(undefined);
		if (storedData.length === 0) {
			// Make sure that mutable fields has a default value:
			populateUndefinedvalues(fetchedFormattedData);
			// setStoredData(fetchedFormattedData);
			handleSetTableValues(fetchedFormattedData);

			setLoading(false);
			return;
		}
		// Make sure that mutable fields has a default value:
		populateUndefinedvalues(fetchedFormattedData);

		//match fetched data, with locally stored data. Transfer the data from mutable fields to the fetched data based on PO id and line no.
		updateStoredDataWithFetchedData(fetchedFormattedData, storedData);
		// setStoredData(fetchedFormattedData);
		handleSetTableValues(fetchedFormattedData);
		setLoading(false);
	}, [storedData]);

	React.useEffect(() => {
		setLoading(true);
		setError("");
		purchaseOrderService
			.apiPurchaseOrderListGet()
			.then((response) => {
				let data: TableData[] = [];
				data = response
					.map((element) => element)
					.flatMap((l) => l.lines ?? {});
				setFetchedFormattedData(data);
				getStoredTableValues()
					.then((res) => {
						if (typeof res !== "undefined") {
							setStoredData(res);
							
						}
					})
					.catch((e) => console.log(e));
			})
			.catch((error: Response) => {
				switch (error.status) {
					case 401:
						signOut();
						break;
					case 500:
						setError(
							"Server error, please contact an administrator"
						);
						break;
					default:
						setError(
							"Something went wrong, please contact an administrator"
						);
				}
				setLoading(false);
				return;
			});
	}, []);
	const filterList = getListOfUniqueObjectsByKey(
		"purchaseOrderId",
		storedData
	);

	return (
		<>
			{loading ? (
				<Loading />
			) : (
				<div className="main-wrapper relative h-screen w-screen px-[20px] py-[10px]">
					<Routes>
						<Route
							index
							element={
								<Home
									baseUrl={baseURL}
									filterList={filterList}
									setSelectedOrders={setSelectedOrders}
									loading={loading}
									error={error}
								/>
							}
						/>
						<Route
							path={RoutePaths.Edit}
							element={
								<Edit
									baseUrl={baseURL}
									setStoredData={setStoredData}
									setRerenderKey={setRerenderKey}
									storedData={storedData}
									selectedOrders={selectedOrders}
								/>
							}
						/>
						<Route
							path={RoutePaths.Nonexistent}
							element={<Navigate to={RoutePaths.Home} replace />}
						/>
					</Routes>
				</div>
			)}
		</>
	);
};
export default MainLayout;
