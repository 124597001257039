import "./App.css";
import "./api";
import * as React from "react";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import RoutePaths from "./common/enums/RoutePaths";
import AuthenticatedWrapper from "./common/components/AuthenticatedWrapper";
import MainLayout from "./common/components/Layout/MainLayout";

interface props { }

declare const window: any;

const App: React.FC<props> = ({ }) => {
	// Rerenderkey is used to force rerender when pressing the back button on the edit page. 
	const [rerenderKey, setRerenderKey] = React.useState<number>(0);
	const baseURL = window?._env_?.BASE_URL ?? "env missing";

	return (
		<div className="h-screen overflow-hidden">
			<Routes>
				<Route path={RoutePaths.Login} element={<LoginPage baseUrl={baseURL} />} />

				{/* Secure routes */}
				<Route element={<AuthenticatedWrapper />}>
					<Route path={RoutePaths.Nonexistent} element={<MainLayout setRerenderKey={setRerenderKey} baseURL={baseURL} key={rerenderKey} />} />
				</Route>

			</Routes>
		</div>
	);
};

export default App;
