import * as React from "react";
import NumberInput from "./NumberInput";
import { ErrorObject } from "../types/ErrorObjectType";

interface props {
    onBlurHandler?: (value: number | undefined) => void
    id: string,
    label: string
    disabled?: boolean
    errorObject: ErrorObject
}

const FocusableNumberInput: React.FC<props> = ({
    onBlurHandler,
    id,
    label,
    disabled = false,
    errorObject: { hasError = false, errorMessage = "" }
}) => {

    return (

        <div className="relative">
            <NumberInput
                id={id}
                extraClasses="placeholder-transparent no-spinner"
                onBlurHandler={onBlurHandler}
                hasError={hasError}
                disabled={disabled}
                placeholder={label}
            />
            <label htmlFor={id} className="absolute left-3 top-0.5 text-xs text-gray-600 cursor-text peer-placeholder-shown:text-base peer-placeholder-shown:top-2 peer-focus:top-0.5 peer-focus:text-xs transition-all duration-200">
                {label}
            </label>
            <p className={(hasError ? 'visible' : "invisible") + " text-rose-600 block"}>{errorMessage}</p>
        </div>

    );
};
export default FocusableNumberInput;