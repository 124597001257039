import { get } from "idb-keyval";
import React from "react";
import { useAuthUser } from "react-auth-kit";
import { TableData } from "../interfaces/TableData";

export function useStorageState<T>(
	storage: Storage,
	key: string | undefined,
	initialValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] {
	const [storedValue, setStoredValue] = React.useState<T>(() => {
		if (typeof key !== "string" || key.length === 0) {
			return initialValue;
		}

		try {
			// Get from storage by key and parse if found
			const item = storage.getItem(key);
			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			// If an error occurred, log it and return initial value
			console.error(error);
			return initialValue;
		}
	});

	// When storedValue changes, persist it in storage
	React.useEffect(() => {
		if (typeof key === "string" && key.length > 0) {
			storage.setItem(key, JSON.stringify(storedValue));
		}
	}, [storage, key, storedValue]);

	return [storedValue, setStoredValue];
}

export const getStoredTableValues = async (key: string): Promise<TableData[] | undefined> => {
	const data = await get(key);
	if (typeof data === "undefined") {
		return [];
	}
	return data;
};