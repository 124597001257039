import * as React from "react";

interface props {
    id: string,
    onBlurHandler?: (value: number | undefined) => void,
    hasError?: boolean,
    disabled?: boolean
    placeholder?: string,
    extraClasses?: string
}

const NumberInput: React.FC<props> = ({
    id,
    onBlurHandler,
    hasError = false,
    disabled,
    placeholder = " ",
    extraClasses =""
}) => {


    const [value, setValue] = React.useState<undefined | number>(undefined);
    const onBlur = () => {
        if (onBlurHandler !== undefined) {
            onBlurHandler(value);
        }
        return
    }

    return (
        <>
            <input
                id={id}
                type="number"
                className={"peer border p-3 pb-1 pt-4 " + (hasError ? "border-rose-600 " : "border-black ") + (disabled ? " bg-slate-100 " : " bg-white ") + (extraClasses)}
                value={value}
                onChange={e => isNaN(e.target.valueAsNumber) ? setValue(undefined) : setValue(e.target.valueAsNumber)}
                onBlur={onBlur}
                disabled={disabled}
                placeholder={placeholder}
            />
        </>
    );
};
export default NumberInput;