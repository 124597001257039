import * as React from "react";
import {
	ColumnDef,
	useReactTable,
	getCoreRowModel,
	RowData,
	Row,
	FilterFn,
	getFilteredRowModel,
} from "@tanstack/react-table";

import TableComponent from "../common/components/Table/TableComponent";
import { EditableInputCell } from "../common/components/Table/EditableInputCell";
import { DropdownBooleanCell } from "../common/components/Table/DropdownBooleanCell";
import { InputTypes } from "../common/enums/Input";
import Button from "../common/components/Button";
import { useNavigate } from "react-router";
import RoutePaths from "../common/enums/RoutePaths";
import {
	calculateTotalBoxQuantities,
	calculateTotalItemQuantities,
	editTableField,
	hasMultipleOrdersSelected,
	removeRowsFromDataset,
	rowIsEdited,
} from "../common/helperfunctions";
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import {
	Configuration,
	PurchaseOrderApi,
	PurchaseOrderLineResponse,
} from "../api";
import { TableData } from "../common/interfaces/TableData";
import {
	backorderError,
	bookingNoError,
	validateRows,
	weightGrossError,
	weightNettoError,
} from "../common/validation/tablerules";
import AnimatedPTag from "../common/components/AnimatedPTag";
import { EditableNumberCell } from "../common/components/Table/EditableNumberCell";
import FocusableNumberInput from "../common/components/FocusableNumberInput";
import FocusableTextInput from "../common/components/FocusableTextInput";
import { ErrorObject } from "../common/types/ErrorObjectType";
import { set, update } from "idb-keyval";
import { getStoredTableValues } from "../common/customHooks/useStorageState";

declare module "@tanstack/react-table" {
	interface TableMeta<TData extends RowData> {
		updateData: (
			rowIndex: number,
			columnId: string,
			value: unknown
		) => void;
	}
}

export interface props {
	baseUrl: string;
	storedData: TableData[];
	setStoredData: React.Dispatch<React.SetStateAction<TableData[]>>;
	setRerenderKey: React.Dispatch<React.SetStateAction<number>>;
	selectedOrders: string[];
}

const Edit: React.FC<props> = ({
	baseUrl,
	setStoredData,
	setRerenderKey,
	storedData,
	selectedOrders,
}) => {
	const navigate = useNavigate();
	const authHeader = useAuthHeader();
	const auth = useAuthUser();

	//TODO: find better way to handle rows Edited detection.
	const [isEdited, setIsEdited] = React.useState<boolean>(false); // Enables complete button when any field is edited

	const [canNavigate, setCanNavigate] = React.useState<boolean>(false);
	const [error, setError] = React.useState<string>("");

	const [weightNettoErrorState, setWeightNettoErrorState] =
		React.useState<ErrorObject>({ hasError: false, errorMessage: "" });
	const [weightGrossErrorState, setWeightGrossErrorState] =
		React.useState<ErrorObject>({ hasError: false, errorMessage: "" });

	//TODO: Move configuration to usercontext.
	const configuration = new Configuration({
		basePath: baseUrl,
		apiKey: authHeader(),
	});
	const purchaseOrderService = new PurchaseOrderApi(configuration);

	const setWeightErrorStates = (rows: TableData[]): void => {
		const errorMessage = "Weight empty";
		let grossError = false;
		let nettoError = false;
		rows.forEach((row) => {
			if (!hasMultipleOrdersSelected(selectedOrders)) {
				if (weightGrossError(row)) {
					grossError = true;
				}
				if (weightNettoError(row)) {
					nettoError = true;
				}
			}
		});
		if (nettoError) {
			setWeightNettoErrorState({
				hasError: true,
				errorMessage: errorMessage,
			});
		}
		if (grossError) {
			setWeightGrossErrorState({
				hasError: true,
				errorMessage: errorMessage,
			});
		}
	};

	const updateData = async (
		attribute: keyof TableData,
		value: TableData[keyof TableData]
	) => {
		update(
			auth()?.type + "_tableData",
			(currentData: TableData[] | undefined): TableData[] => {
				if (typeof currentData === "undefined") return [];
				const newData = currentData.map((row: TableData) => {
					if (
						selectedOrders.some(
							(purchaseOrderId) =>
								purchaseOrderId === row.purchaseOrderId
						)
					) {
						return editTableField(row, attribute, value);
					}
					return row;
				});
				setStoredData(newData);
				return newData;
			}
		)
			.then()
			.catch((e) => console.log(e));
	};

	const onBookingNumberBlurHandler = (bookingNo: string | undefined) => {
		update(
			auth()?.type + "_tableData",
			(currentData: TableData[] | undefined): TableData[] => {
				if (typeof currentData === "undefined") return [];
				const newData = currentData.map((row: TableData) => {
					if (
						selectedOrders.some(
							(purchaseOrderId) =>
								purchaseOrderId === row.purchaseOrderId
						)
					) {
						row.bookingNo = bookingNo;
					}
					return row;
				});
				setStoredData(newData);
				return newData;
			}
		)
			.then()
			.catch((e) => console.log(e));
	};

	const onWeightNettoBlurHandler = async (
		weight: number | undefined
	): Promise<void> => {
		await updateData("weightNetto", weight ?? 0);
		if (weight !== undefined) {
			setWeightNettoErrorState({ hasError: false, errorMessage: "" });
		}
	};

	const onWeightGrossBlurHandler = async (
		weight: number | undefined
	): Promise<void> => {
		await updateData("weightGross", weight ?? 0);
		if (weight !== undefined) {
			setWeightGrossErrorState({ hasError: false, errorMessage: "" });
		}
	};

	const updateQuantityPiecesEnteredWhenPrepackTrue = (
		enteredValue: number | undefined,
		row: Row<TableData>
	): void => {
		if (row.original.prepack) {
			/* Extract amount of pieces for each box */
			const piecesPerBox =
				(row.original.quantityItem ?? 0) /
				(row.original.quantityBox ?? 0);
			const totalPieces = piecesPerBox * (enteredValue ?? 0);

			/* poNumber and skuDetails act as primary key of a row  */
			const poNumber = row.original.purchaseOrderId;
			const skuDetails = row.original.skuDetails;

			update(
				auth()?.type + "_tableData",
				(currentData: TableData[] | undefined): TableData[] => {
					if (typeof currentData === "undefined") return [];
					const newData = currentData.map((currentRow) => {
						if (
							poNumber === currentRow.purchaseOrderId &&
							skuDetails === currentRow.skuDetails
						) {
							currentRow.quantityItemEntered = totalPieces ?? 0;
						}
						return currentRow;
					});
					setStoredData(newData);
					return newData;
				}
			)
				.then()
				.catch((e) => console.log(e));
		}
	};

	const selectFilterFn: FilterFn<any> = (
		row: Row<TableData>,
		columnId,
		value,
		addMeta
	) => {
		if (value === undefined || value.length === 0) {
			return true;
		}
		if (row.original.purchaseOrderId === undefined) {
			return true;
		}
		return value.includes(row.original?.purchaseOrderId);
	};

	const defaultColumns: ColumnDef<TableData>[] = [
		{
			id: "purchaseOrderId",
			header: "PO",
			footer: "PO",
			accessorKey: "purchaseOrderId",
			cell: (info) => info.renderValue(),
			size: 75,
			enableColumnFilter: true,
			filterFn: selectFilterFn,
		},
		{
			id: "itemNo",
			header: "Item No.",
			footer: "Item No.",
			accessorKey: "itemNo",
			cell: (info) => info.renderValue(),
			size: 170,
		},
		{
			id: "skuDetails",
			header: "SKU Details",
			footer: "SKU Details",
			accessorKey: "skuDetails",
			cell: (info) => info.renderValue(),
			size: 100,
		},
		{
			id: "name",
			header: "Name",
			footer: "Name",
			accessorKey: "name",
			cell: (info) => info.renderValue(),
			size: 150,
		},
		{
			id: "bookingNo",
			header: "Booking No.",
			accessorKey: "bookingNo",
			footer: "Booking No.",
			cell: ({ cell, row, column, table }) => (
				<EditableInputCell
					{...{
						cell: cell,
						row: row,
						column: column,
						tableFunction: table,
						inputType: InputTypes.TEXT,
						hasError: (row) => {
							return bookingNoError(row.original);
						},
					}}
				/>
			),
			size: 125,
		},

		{
			id: "quantityBox",
			header: "Order QTY Box",
			accessorKey: "quantityBox",
			footer: "Order QTY Box",
			cell: (info) => info.renderValue(),
			size: 100,
		},
		{
			id: "quantityBoxEntered",
			header: "Packing list QTY Box",
			footer: "Packing list QTY Box",
			accessorKey: "quantityBoxEntered",
			cell: ({ cell, row, column, table }) => (
				<EditableNumberCell
					{...{
						cell: cell,
						row: row,
						column: column,
						tableFunction: table,
						step: 0.1,
						min: 0,
						inputType: InputTypes.NUMBER,
						processValue: (value) => {
							if (row.original.prepack) {
								return Math.abs(Math.round(Number(value)));
							}
							return Math.abs(Number(value));
						},
						additionalOnBlurAction: (value) => {
							updateQuantityPiecesEnteredWhenPrepackTrue(
								value,
								row
							);
						},
					}}
				/>
			),
			size: 150,
		},
		{
			id: "quantityBoxDeviation",
			header: "Deviation QTY Box ",
			footer: "Deviation QTY Box ",
			cell: ({ row }) => {
				if (row.original.quantityBox === undefined) return;
				return (
					(row.original.quantityBoxEntered || 0) -
					row.original.quantityBox
				);
			},
			size: 100,
		},
		{
			id: "quantityItem",
			header: "Order QTY Pcs",
			footer: "Order QTY Pcs",
			accessorKey: "quantityItem",
			cell: (info) => info.renderValue(),
			size: 120,
		},
		{
			id: "quantityItemEntered",
			header: "Packing list QTY Pcs",
			footer: "Packing list QTY Pcs",
			accessorKey: "quantityItemEntered",
			cell: ({ cell, row, column, table }) => (
				<EditableNumberCell
					{...{
						cell: cell,
						row: row,
						column: column,
						tableFunction: table,
						step: 1,
						min: 0,
						inputType: InputTypes.NUMBER,
						disabled: row.original.prepack,
						processValue: (value) => {
							return Math.abs(Math.round(Number(value)));
						},
					}}
				/>
			),
			size: 120,
		},
		{
			id: "quantityPcsDeviation",
			header: "Deviation QTY Pcs",
			footer: "Deviation QTY Pcs",
			cell: ({ row }) => {
				if (row.original.quantityItem === undefined) return;
				return (
					(row.original.quantityItemEntered || 0) -
					row.original.quantityItem
				);
			},
			size: 100,
		},
		{
			id: "backorder",
			header: "Backorder",
			accessorKey: "backorder",
			footer: "Backorder",
			cell: ({ cell, row, column, table }) => (
				<DropdownBooleanCell
					{...{
						cell: cell,
						row: row,
						column: column,
						table: table,
						hasError: (row) => {
							return backorderError(row.original);
						},
					}}
				/>
			),
			size: 100,
		},
		{
			id: "weightNetto", // Column is set to hidden, in table state. Requested in Devops #14723
			header: "Weight Netto (kg)",
			accessorKey: "weightNetto",
			footer: "Weight Netto (kg)",

			cell: ({ cell, row, column, table }) => (
				<EditableNumberCell
					{...{
						cell: cell,
						row: row,
						column: column,
						tableFunction: table,
						step: 0.1,
						min: 0,
						hasError: (row) => {
							return weightNettoError(row.original);
						},
						processValue: (value) => {
							return Math.abs(Number(value));
						},
					}}
				/>
			),
			size: 120,
		},
		{
			id: "weightGross", // Column is set to hidden, in table state. Requested in Devops #14723
			header: "Weight Gross (kg)",
			accessorKey: "weightGross",
			footer: "Weight Gross (kg)",
			cell: ({ cell, row, column, table }) => (
				<EditableNumberCell
					{...{
						cell: cell,
						row: row,
						column: column,
						tableFunction: table,
						step: 0.1,
						min: 0,
						hasError: (row) => {
							return weightGrossError(row.original);
						},
						processValue: (value) => {
							return Math.abs(Number(value));
						},
					}}
				/>
			),
			size: 120,
		},
		{
			id: "packageNo",
			header: "Package No.",
			accessorKey: "packageNo",
			footer: "Package No.",
			cell: ({ cell, row, column, table }) => (
				<EditableInputCell
					{...{
						cell: cell,
						row: row,
						column: column,
						tableFunction: table,
						inputType: InputTypes.TEXT,
					}}
				/>
			),
			size: 125,
		},
	];
	const columns = React.useMemo<typeof defaultColumns>(
		() => defaultColumns,
		[]
	);

	React.useEffect(() => {
		table?.getColumn("purchaseOrderId")?.setFilterValue(selectedOrders);
		if (hasMultipleOrdersSelected(selectedOrders)) {
			const errorMessage = "Multiple item numbers";
			setWeightGrossErrorState({
				hasError: true,
				errorMessage: errorMessage,
			});
			setWeightNettoErrorState({
				hasError: true,
				errorMessage: errorMessage,
			});
		}
	}, [selectedOrders, storedData]);

	React.useEffect(() => {
		if (canNavigate) {
			navigate(RoutePaths.Home);
		}
		setCanNavigate(false);
	}, [storedData]);

	const table = useReactTable({
		data: storedData,
		columns: columns,

		state: {
			columnVisibility: {
				weightNetto: false,
				weightGross: false,
			},
		},
		meta: {
			updateData: (rowIndex, columnId, value) => {
				update(
					auth()?.type + "_tableData",
					(currentData: TableData[] | undefined): TableData[] => {
						if (typeof currentData == "undefined") return [];
						const newData = currentData.map((row, index) => {
							if (index === rowIndex) {
								return {
									...currentData[rowIndex]!,
									[columnId]: value,
								};
							}
							return row;
						});
						setStoredData(newData);
						return newData;
					}
				)
					.then(() => {
						if (!isEdited) {
							setIsEdited(true);
						}
						if (!hasMultipleOrdersSelected(selectedOrders)) {
							const filteredData = table.getFilteredRowModel();
							const errorMessage = "Weight empty";
							setWeightNettoErrorState({
								hasError:
									filteredData.rows.filter(
										(row) =>
											weightNettoError(row.original) ===
											true
									).length > 0,
								errorMessage: errorMessage,
							});

							setWeightGrossErrorState({
								hasError:
									filteredData.rows.filter(
										(row) =>
											weightGrossError(row.original) ===
											true
									).length > 0,
								errorMessage: errorMessage,
							});
						}
					})
					.catch((e) => console.log(e));
			},
		},
		getFilteredRowModel: getFilteredRowModel(),
		getCoreRowModel: getCoreRowModel(),
	});

	const handleCompleteClick = async () => {
		const data = await getStoredTableValues(auth()?.type + "_tableData");
		if (typeof data === "undefined") return;
		/* Get all edited fields, by looking for changes in entered data for the selected purchase orders. */

		const changedRows = data.filter((row) => {
			return (
				rowIsEdited(row) &&
				selectedOrders.some(
					(purchaseOrderId) => purchaseOrderId === row.purchaseOrderId
				)
			);
		});

		setWeightErrorStates(changedRows);
		/** Validate any edited rows */
		const validatedRows = changedRows.filter((row) => {
			return validateRows(row);
		});

		/** Prepare the rows for the API */
		let purchaseorderLines: PurchaseOrderLineResponse[] = [];

		validatedRows.forEach((row) => {
			purchaseorderLines.push({
				purchaseOrderId: row.purchaseOrderId,
				lineNo: row.lineNo,
				bookingNo: row.bookingNo,
				packageNo: row.packageNo,
				itemNo: row.itemNo,
				variant: row.variant,
				quantityItem: row.quantityItemEntered,
				quantityBox: row.quantityBoxEntered,
				backOrder: row.backorder,
				weightNet: row.weightNetto,
				weightGross: row.weightGross,
			} as PurchaseOrderLineResponse);
		});

		if (validatedRows.length !== changedRows.length) {
			setError(
				`You have ${
					changedRows.length - validatedRows.length
				} lines that cannot be validated`
			);
			return;
		}
		setError("");

		purchaseOrderService
			.apiPurchaseOrderBatchUpdatePost(purchaseorderLines)
			.then((response) => {
				update(
					auth()?.type + "_tableData",
					(currentData: TableData[] | undefined): TableData[] => {
						if (typeof currentData === "undefined") return [];
						const newData = removeRowsFromDataset(
							currentData,
							validatedRows
						);
						setStoredData(newData);
						return newData;
					}
				)
					.then()
					.catch((e) => console.log(e));

				// setStoredData(removeRowsFromDataset(storedData, validatedRows));
				setCanNavigate(true);
			})
			.catch((response: Response) => {
				switch (response.status) {
					case 500:
						setError(
							"Internal error. Please contact the system administrator."
						);
						break;
					default:
						setError(
							"Something went wrong. Please contact the system administrator"
						);
				}
			});
	};
	const handleNavigateBack = async () => {
		await updateData("weightNetto", 0);
		await updateData("weightGross", 0);

		setRerenderKey(Math.random());
		navigate(RoutePaths.Home);
	};

	return (
		<>
			<div className="flex justify-between mb-[10px]">
				<div className="flex gap-2">
					<Button
						onClick={handleCompleteClick}
						text={"Complete"}
						disabled={!isEdited}
					/>
					<div
						className={
							(error !== "" ? "visible" : "invisible") +
							" py-1 px-3 rounded bg-red-300/25"
						}
					>
						{error}
					</div>
				</div>
				<div className="flex">
					<Button onClick={handleNavigateBack} text={"Back"} />
				</div>
			</div>
			<div className="flex mb-[10px] flex-wrap">
				<div className="pr-2">
					<FocusableTextInput
						id="booking-number"
						label="Booking Number"
						onBlurHandler={onBookingNumberBlurHandler}
					/>
				</div>
				<div className="pr-2 min-w-[10px]">
					<FocusableNumberInput
						id="weight-netto"
						label="Total Weight Netto"
						errorObject={weightNettoErrorState}
						disabled={hasMultipleOrdersSelected(selectedOrders)}
						onBlurHandler={onWeightNettoBlurHandler}
					/>
				</div>
				<div className="pr-2">
					<FocusableNumberInput
						id="weight-gross"
						label="Total Weight Gross"
						errorObject={weightGrossErrorState}
						disabled={hasMultipleOrdersSelected(selectedOrders)}
						onBlurHandler={onWeightGrossBlurHandler}
					/>
				</div>
			</div>
			<TableComponent
				table={table}
				headerCount={table.getVisibleFlatColumns().length} // Scrolling virtual rows, will bug out, if header count is not set.
				customHeight="h-[calc(100vh-295px)] twoLines:h-[calc(100vh-252px)] oneLine:h-[calc(100vh-182px)]"
			/>
			<div className="flex justify-between ">
				<div className="flex flex-wrap gap-6  ">
					<AnimatedPTag
						classes="font-medium"
						text="List Box Sum:"
						animatedValue={calculateTotalBoxQuantities(table)}
					/>
					<AnimatedPTag
						classes="font-medium"
						text="List Pieces Sum: "
						animatedValue={calculateTotalItemQuantities(table)}
					/>
				</div>
			</div>
		</>
	);
};
export default Edit;
