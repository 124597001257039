import { TableData } from "../interfaces/TableData";

/** HELPER FUNCTIONS  */
const prepack = (row: TableData): boolean => {
    return row.prepack === true;
}

const quantityBoxEnteredUndefined = (row: TableData): boolean => {
    return row.quantityBoxEntered === undefined;
}

const quantityBoxEnteredHasData = (row: TableData): boolean => {
    return !quantityBoxEnteredUndefined(row) && row.quantityBoxEntered! > 0;
}

const quantityBoxZero = (row: TableData): boolean => {
    return row.quantityBoxEntered === 0;
}


const quantityItemUndefined = (row: TableData): boolean => {
    return row.quantityItemEntered === undefined;
}

const quantityItemTotalBiggerThanZero = (row: TableData): boolean => {

    return !quantityItemUndefined(row) && (row.quantityItem! - row.quantityItemEntered! > 0);
}

const quantityItemEnteredHasData = (row: TableData): boolean => {
    return !quantityItemUndefined(row) && row.quantityItemEntered! > 0;
}

const itemOrBoxHasData = (row: TableData): boolean => {
    return quantityBoxEnteredHasData(row) || quantityItemEnteredHasData(row);
}


const bookingNoUndefined = (row: TableData): boolean => {
    return row.bookingNo === undefined;
}

const bookingNoEmpty = (row: TableData): boolean => {
    return bookingNoUndefined(row) || row.bookingNo === "";
}

const packageNoUndefined = (row: TableData): boolean => {
    return row.packageNo === undefined;
}

const packageNoEmpty = (row: TableData): boolean => {
    return packageNoUndefined(row) || row.packageNo === "";
}

const weightNettoUndefined = (row: TableData): boolean => {
    return row.weightNetto === undefined;
}

const weightNettoEmpty = (row: TableData): boolean => {
    return weightNettoUndefined(row) || row.weightNetto === 0;
}

const backorerUndefined = (row: TableData): boolean => {
    return row.backorder === undefined;
}

const weightGrossUndefined = (row: TableData): boolean => {
    return row.weightGross === undefined;
}

const weightGrossEmpty = (row: TableData): boolean => {
    return weightGrossUndefined(row) || row.weightGross === 0;
}

// const anyFieldEdited = (row: TableData): boolean => {
//     return !bookingNoEmpty(row) || !packageNoEmpty(row) || quantityItemEnteredHasData(row) || quantityBoxEnteredHasData(row) || !backorerUndefined(row) || !weightNettoEmpty(row) || !weightGrossEmpty(row);
// }

const anyFieldEdited = (row: TableData): boolean => {
    return  !packageNoEmpty(row) || quantityItemEnteredHasData(row) || quantityBoxEnteredHasData(row) || !backorerUndefined(row) || !weightNettoEmpty(row) || !weightGrossEmpty(row);
}

const backorderBaseRule = (row: TableData): boolean => {
    return backorerUndefined(row) && anyFieldEdited(row);
}


/** VALIDATIONS  */
export const bookingNoError = (row: TableData): boolean => {

    return (!prepack(row) && quantityItemEnteredHasData(row) && bookingNoEmpty(row))
        || (prepack(row) && itemOrBoxHasData(row) && bookingNoEmpty(row));
}

export const weightNettoError = (row: TableData): boolean => {
    return (!prepack(row) && quantityItemEnteredHasData(row) && weightNettoEmpty(row))
        || (prepack(row) && itemOrBoxHasData(row) && weightNettoEmpty(row));
}

export const weightGrossError = (row: TableData): boolean => {
    return (!prepack(row) && quantityItemEnteredHasData(row) && weightGrossEmpty(row))
        || (prepack(row) && itemOrBoxHasData(row) && weightGrossEmpty(row));
}

export const backorderError = (row: TableData): boolean => {
    return (!prepack(row) && backorderBaseRule(row) && quantityItemTotalBiggerThanZero(row)) // anyfieldEdited is checked to NOT mark every backorder field as eronous pr. default  
        || (prepack(row) && backorderBaseRule(row) &&  (quantityItemTotalBiggerThanZero(row) || quantityBoxZero(row)));
}

export const validateRows = (row: TableData): boolean => {
    return (!bookingNoError(row) && !weightNettoError(row) && !weightGrossError(row) && !backorderError(row))
}