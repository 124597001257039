
import { Row } from "@tanstack/react-table";
import * as React from "react";
import { TableData } from "../../interfaces/TableData";

export const DropdownBooleanCell = ({
    cell,
    row,
    column,
    table,
    hasError
}: { cell?: any, row: any, column: any, table: any, hasError?: (row: Row<TableData>) => boolean }) => {
    const translateToBool = (value: string | undefined) => {
        if (value === "") {
            return undefined;
        }
        if (value === "YES") {
            return true
        }
        return false;
    }

    const translateToString = (value: boolean | undefined) => {
        if (value === undefined) {
            return "";
        }
        if (value) {
            return "YES";
        }
        return "NO";
    }
    const selectOptions = ["YES", "NO"];
    const initialValue = cell.getValue();
    const initialStringValue = translateToString(initialValue);
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);
    const [stringValue, setStringValue] = React.useState(translateToString(initialValue));
    // When the input is blurred, we'll call our table meta's updateData function


    const onBlur = () => {
        if (initialStringValue !== stringValue) {
            table.options.meta?.updateData(row.index, column.id, translateToBool(stringValue));
        }
    }

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    return (
        <select
            className={"border-2 " + (hasError?.(row) ? " border-rose-600" : " border-transparent")}
            value={stringValue || ''}
            onChange={e => setStringValue(e.target.value)}
            onBlur={onBlur}
        >
            <option value=''></option>
            {selectOptions.map((value, index) => (
                <option key={index} value={value}>{value}</option>
            ))}
        </select>
    )
}